import React, { useState } from "react"
import { TextInput } from "../Form/TextInput"
import {
  CustomForm,
  SubmitButton,
  SuccessMessage,
  ContactFlexGrid,
  SidePanelGrid,
} from "./style"
import { Icon } from "utils/getIcon"
import { FlexRow, FlexCol } from "components/FlexGrid"

const nameIcon = <Icon name="name" />
const titleIcon = <Icon name="title" />
const messageIcon = <Icon name="message" />
const phoneIcon = <Icon name="phone" />
const mailIcon = <Icon name="mail" />
const latteIcon = <Icon name="latte" />

const FormEg = () => {
  const [submitted, setSubmitted] = useState(false)

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const onSubmit = values => {
    setSubmitted(true)
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...values }),
    })
  }
  return (
    <ContactFlexGrid gap={[0, 0, 100, 140]}>
      <FlexRow>
        <FlexCol xs={12} md={8}>
          <CustomForm
            submitted={submitted}
            noValidate
            onSubmit={onSubmit}
            netlify
            name="contact"
          >
            <SuccessMessage submitted={submitted}>
              Thanks for contacting us. We’ll be in touch as soon as possible!
            </SuccessMessage>

            {/* <BackToHome
          submitted={submitted}
          label="Back to the homepage"
          link="/"
        /> */}

            <TextInput name="subject" type="hidden" value="Website Enquiry" />

            <TextInput
              name="fullname"
              error="Please enter a name "
              floatingLabel="Your full name"
              icon={nameIcon}
            />

            <TextInput
              name="title"
              error="Please enter a title"
              floatingLabel="Title"
              icon={titleIcon}
            />
            <TextInput
              name="company"
              error="Please enter a company"
              floatingLabel="Company"
              icon={mailIcon}
            />

            <TextInput
              type="email"
              name="email"
              error="Please enter a valid email address"
              floatingLabel="Email address"
              icon={titleIcon}
            />

            <TextInput
              name="phone"
              error="Please enter a phone number"
              floatingLabel="Phone"
              icon={phoneIcon}
            />

            <TextInput
              name="coffee"
              floatingLabel="Latte? English Breakfast? Soy milk? One sugar or two?"
              icon={latteIcon}
            />

            <TextInput
              name="message"
              type="textarea"
              error="Please enter a message"
              floatingLabel="Your message"
              icon={messageIcon}
            />

            <SubmitButton submit label="Submit" />
          </CustomForm>
        </FlexCol>
        <FlexCol xs={0} md={4}>
          <SidePanelGrid fullWidth>
            <FlexRow>
              <FlexCol xs={12}>
                <h4 className="orange">Where To Find Us</h4>
                <p>Level 1, 554 High Street Prahran VIC 3181</p>
                {/* <p>+ 61 3 9522 9922</p> */}
              </FlexCol>
              <FlexCol xs={12}>
                <h4>Kerrie Ryan</h4>
                <p className="role bold">Managing Director</p>
                <p>
                  <span className="bold">M </span>+61 419 804 424
                </p>
                <a href="mailto:kerrie.ryan@communicado.com.au">Email Kerrie</a>
              </FlexCol>

              <FlexCol xs={12}>
                <h4>Lucy Houldsworth</h4>
                <p className="role bold">Director</p>
                <p>
                  <span className="bold">M </span>+61 421 079 543
                </p>
                <a href="mailto:lucy.houldsworth@communicado.com.au">
                  Email Lucy
                </a>
              </FlexCol>
            </FlexRow>
          </SidePanelGrid>
        </FlexCol>
      </FlexRow>
    </ContactFlexGrid>
  )
}

export default FormEg

import styled from "styled-components"
import { Form } from "../Form"
import { Button } from "../Button"
import { device } from "utils/breakpoints.js"
import { Label } from "components/Button/style"
import { FlexGrid } from "components/FlexGrid"
export const SubmitButton = styled(Button)`
  height: 64px;
  width: 165px;
  float: right;
  margin-top: 10px;
  background: #ff7f41;
  @media ${device.desktop} {
    height: 72px;
    font-size: 20px;
    margin-top: 15px;
  }

  ${Label} {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;

    font-family: TitlingGothic, Arial, sans-serif;
    @media ${device.desktop} {
      font-size: 24px;
    }
  }

  :hover {
    background: #de6a32;
  }
`

export const CustomForm = styled(Form)`
  margin-bottom: 50px;
  max-width: 760px;
  @media ${device.desktop} {
    margin-bottom: 0px;
  }

  > * {
    display: ${props => props.submitted && "none"};
  }
`

export const Request = styled.p`
  color: ${props => props.theme.lightBlue};
  font-size: 18px;
  margin-bottom: 30px;
  display: block !important;

  @media ${device.desktop} {
    font-size: 24px;
    margin-bottom: 30px;
  }
`

export const SuccessMessage = styled(Request)`
  font-weight: 600;
  display: ${props => (props.submitted ? "block" : "none")} !important;

  @media ${device.desktop} {
    font-size: 20px;
    margin-bottom: 40px;
  }
`
export const BackToHome = styled(SubmitButton)`
  width: auto;
  display: ${props => (props.submitted ? "inline-flex" : "none")} !important;
  @media ${device.desktop} {
    width: 260px;
    float: left;
  }
`

export const ContactFlexGrid = styled(FlexGrid)`
  padding-bottom: 60px;
  @media ${device.desktop} {
    padding-bottom: 90px;
  }
  @media ${device.large} {
    padding-bottom: 120px;
  }
`
export const SidePanelGrid = styled(FlexGrid)`
  display: none;
  position: relative;

  @media ${device.desktop} {
    display: flex;
    flex-direction: column;
  }

  ::before {
    content: "";
    position: absolute;
    background: #e9e4e1;
    height: 915px;
    opacity: 0.8;
    width: 1px;
    top: 0;
    left: -50px;

    @media ${device.large} {
      left: -70px;
    }
  }

  h4 {
    font-size: 24px;
    margin-bottom: 7px;
    &.orange {
      color: #ff7f41;
    }
  }

  p,
  a {
    font-size: 20px;
    max-width: 230px;
  }

  a {
    font-weight: 600;
    color: #3f3f3f;
    position: relative;
    opacity: 1;
    transition:100ms;

    :after {
      content: "";
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 1px;
      background: #ff7f41;
    }

    :hover {
      opacity: 0.7;
    }
  }

  .bold {
    font-weight: 600;
  }

  .role {
    margin-bottom: 7px;
  }
`

import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { ContactForm } from "components/ContactForm"
import { PageHeaderSection } from "components/PageHeaderSection"

const ContactPage = () => {
  return (
    <Layout>
      <SEO title="Let’s grab a cuppa" />
      <PageHeaderSection
        title="Let’s grab a cuppa"
        text1={`We’ll solve your biggest challenges without forgetting the small stuff; like how you have your coffee. Get in touch with Kerrie or Lucy, or drop us a message below.`}
      />
      <ContactForm />
    </Layout>
  )
}

export default ContactPage
